#project-header {
    flex-shrink: 1;
    position: relative;

    .main-group .sidebar-toggle-div {
        visibility: hidden;
    }

    .main-group.full-sidebar .sidebar-toggle-div {
        visibility: visible;
        justify-content: end;
        display: flex;
        padding: 10px 10px 0 0;
    }

    .header-top {
        margin-top: 10px;
    }

    .main-group.full-sidebar .header-top {
        margin-top: 0px;
    }

    .dummy-header {
        width: 85px;
        height: 100%;
    }

    .full-sidebar {
        
        width: 220px !important;
    }

    .marginTop .header-top{
        margin-top: 15px !important;
    }

    .main-group {
        transition: ease-in-out 0.5s;
        z-index: 10;
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        background-color: var(--white-color);
        border: 1px solid var(--border-dadcdf);
        border-radius: 6px;
        height: calc(100% + 10px);
        min-height: 628px;
        display: flex;
        flex-direction: column;

        .header-top {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 48px;
            position: relative;

            .company-logo{
                min-height: 55px;
            }
            .company-logo img {
                margin-bottom: 10px;
            }

        }

        .header-top::after {
            content: '';
            background: #eaeaea;
            height: 1px;
            width: 45%;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            position: absolute;
        }

        .icon-div {
            color: black;
        }

        .icon-div.active .icon-right-side {
            background-color: var(--selected-shade);
        }

        .icon-div.active .icon-right-side::before {
            content: '';
            position: absolute;
            width: 3px;
            height: 100%;
            background: var(--theme-color);
            border-radius: 0px 3px 3px 0px;
            opacity: 1;
            transition: 0.3s;
            top: 0;
            left: 0;
        }

        .icon-div.active .icon-div-left svg {
            fill: var(--theme-color);
        }

        .icon-div.active .main-menu-name {
            color: var(--theme-color);
        }

        .menu {
            min-height: 500px;
            padding-top: 15px;

            .menu-ul {
                margin: 0px;
                padding-left: 0px;
            }

            .main-group-icon {
                transition: 1s;
                border-radius: 6px;
                padding: 12px;
            }

            .main-group-icon .anticon {
                position: relative;
                z-index: 2;
            }

            .main-menu-name {
                display: none;
                font-family: "Roboto";
                color: var(--detailed-font);
                font-weight: 500;
                font-size: 14px;
                align-self: center;
                overflow: hidden;
                text-overflow: ellipsis;
                text-wrap: nowrap;
            }

            .anticon svg {
                height: 22px;
                width: 22px;
                fill: #4f4f4f;
            }

            .main-menu-arrow {
                padding: 5px;
                display: none;
            }

            .sub-ul {
                display: none;
            }
        }
    }

    .header-first-li .icon-div {
        border-radius: 6px 0px 0px 6px;
        margin-bottom: 10px;
        display: flex;
        position: relative;
        transition: 1s;
        display: flex;
        cursor: pointer;
    }

    .icon-right-side {
        margin-right: 18px;
        border-radius: 6px 0px 0px 6px;
        width: 100%;
        max-height: 50px;
        margin-left: 18px;
    }

    .icon-div-left {
        width: 100%;
    }

    .li-full-content {
        position: relative;
    }

    /* during hover and also active in both case color  */
    .header-first-li:hover .icon-right-side,
    .main-menu-open .icon-right-side {
        background-color: var(--selected-shade);
    }

    .main-menu-arrow i {
        font-size: 25px;
    }

    .main-menu-open .main-menu-name,
    .main-menu-open .main-menu-arrow i {
        color: var(--theme-color);
    }

    .header-first-li:hover .main-menu-name,
    .header-first-li:hover .main-menu-arrow i {
        color: var(--theme-color);
    }

    .menu .header-first-li:hover .main-group-icon .anticon svg,
    .main-group .menu .main-menu-open .main-group-icon .anticon svg {
        fill: var(--theme-color);
    }

    .header-first-li:hover .icon-right-side::before {
        content: '';
        position: absolute;
        width: 3px;
        height: 100%;
        background: var(--theme-color);
        border-radius: 0px 3px 3px 0px;
        opacity: 1;
        transition: 0.3s;
        top: 0;
        left: 0;
    }

    .main-menu-open .icon-right-side::before {
        content: '';
        position: absolute;
        width: 3px;
        height: 100%;
        background: var(--theme-color);
        border-radius: 0px 3px 3px 0px;
        opacity: 1;
        transition: 0.3s;
        top: 0;
        left: 0;
    }

    /* when sidebar is open in full width */
    .full-sidebar {
        .main-menu-arrow {
            display: block !important;
        }

        .main-menu-arrow.open-arrow {
            transform: rotate(90deg);
        }

        .sub-ul {
            display: block !important;
        }

        .main-menu-name {
            display: block !important;
        }
    }

    /* if dropdown open then this class add  */
    .menu .main-menu-open .main-menu-name {
        color: var(--theme-color);
    }

    .main-sub-menu {
        margin-left: 20px;
        position: relative;
    }

    .main-sub-menu {
        ul::before {
            z-index: 1;
            width: 15px;
            height: calc(100% - 11px);
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            margin-left: 18px;
            background-image: url('../App_Details_theme/images/Group\ 481007274.png');
            background-repeat: no-repeat;
            background-position: left bottom;
        }

        ul li {
            padding-top: 10px;
        }

        .sub-menu-name {
            padding: 0;
            color: #888888;
            font-size: 14px;
            font-weight: 500;
            transition: 0.3s;
            position: relative;
            border-bottom: 1px solid transparent;
            display: inline;
            border-radius: 0;
        }

        .submenu-link {
            padding-left: 10px;
        }

        .submenu-link .sub-menu-name {
            border-bottom: 1px solid transparent;
        }

        .submenu-link.active .sub-menu-name {
            color: var(--theme-color);
            border-color: var(--theme-color);
            font-weight: 600;
        }
    }
}

@media (max-width: 768px) {
    #project-header {
        .main-group {
            .main-sub-menu ul::before {
                height: 100%;
            }

            .main-sub-menu .sub-menu-name {
                font-size: 9px;
            }

            .main-menu-arrow i {
                font-size: 14px;
            }

            .icon-div .icon-div-left svg {
                width: 12px;
                height: 12px;
            }

            .menu .main-menu-name {
                font-size: 10px;
            }

            .icon-right-side {
                max-height: 30px;
            }

            .main-sub-menu ul li {
                padding-top: 7px;
            }
            .header-top .company-logo{
                min-height: 20px;
            }
        }
    }
}

@media (min-width: 769px) and (max-width:991px) {
    #project-header {
        .main-group {
            .main-sub-menu ul::before {
                height: calc(100% - 5px);
            }

            .main-sub-menu .sub-menu-name {
                font-size: 11px;
            }

            .main-menu-arrow i {
                font-size: 16px;
            }

            .icon-div .icon-div-left svg {
                width: 15px;
                height: 15px;
            }

            .menu .main-menu-name {
                font-size: 12px;
            }

            .icon-right-side {
                max-height: 35px;
            }

            .main-sub-menu ul li {
                padding-top: 10px;
            }
            .header-top .company-logo{
                min-height:20px;
            }
        }
    }
}

@media (min-width: 991px) and (max-width:1200px) {
    #project-header {
        .main-group {
            .main-sub-menu ul::before {
                height: calc(100% - 6px);
            }

            .main-sub-menu .sub-menu-name {
                font-size: 12px;
            }

            .main-menu-arrow i {
                font-size: 22px;
            }

            .icon-div .icon-div-left svg {
                width: 20px;
                height: 20px;
            }

            .menu .main-menu-name {
                font-size: 13px;
            }

            .icon-right-side {
                max-height: 40px;
            }

            .menu .main-group-icon {
                padding: 10px;
            }

            .main-sub-menu .submenu-link {
                padding-left: 6px;
            }

            .main-sub-menu ul li {
                padding-top: 9px;
            }
        }
    }
}

@media (min-width: 1201px) and (max-width:1400px) {
    #project-header {
        .main-group {
            .main-sub-menu ul::before {
                height: calc(100% - 10px);
            }

            .main-sub-menu .sub-menu-name {
                font-size: 13px;
            }

            .main-menu-arrow i {
                font-size: 23px;
            }

            .icon-div .icon-div-left svg {
                width: 19px;
                height: 19px;
            }

            .menu .main-menu-name {
                font-size: 13px;
            }

            .icon-right-side {
                max-height: 40px;
            }

            .menu .main-group-icon {
                padding: 11px;
            }

            .main-sub-menu .submenu-link {
                padding-left: 8px;
            }

            .main-sub-menu ul li {
                padding-top: 9px;
            }
        }
    }
}
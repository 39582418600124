/*main col*/
#dashboard-page #Categories .col-lg-9.header-custom-col {
    width: 82.33%;
    transition: 0.3s;
}

#dashboard-page #Categories .col-lg-9.header-custom-col.expand {
    width: 95%;
}

#dashboard-page #Categories .app-detail-icon {
    width: 36px;
    height: 36px;
    border-radius: 4px;
}

/*top-header*/
/* #dashboard-page #Categories .top-header {
	background: var(--white-color);
	padding: 8px 20px;
	border: 1px solid var(--border-dadcdf);
	border-radius: 8px;
} */

#dashboard-page #Categories .my-info-main {
    gap: 8px;
}

#dashboard-page #Categories .custom-height {
    height: 62px;
}

.cate-image .form-control {
    /* padding: 0px 0px !important; */
}

#dashboard-page #Categories .top-header .left h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    color: #444444;
}

#dashboard-page #Categories .top-header .right .notification {
    margin-right: 28px;
    position: relative;
    transition: 0.3s;
}

#dashboard-page #Categories .top-header .right .notification svg.main-svg {
    cursor: pointer;
}

#dashboard-page #Categories .top-header .right .notification:hover svg.main-svg path {
    fill: var(--theme-color);
}

#dashboard-page #Categories .top-header .right .notification .no {
    background: #FF4D4F;
    border-radius: 100%;
    height: 19px;
    width: 19px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    right: -11px;
    top: -11px;
    font-size: 13px;
    font-weight: 700;
    font-family: Roboto Mono;
    cursor: pointer;
}

/*create project*/
#dashboard-page #Categories .project {
    /* margin-top: 10px; */
    background: var(--white-color);
    border: 1px solid var(--border-dadcdf);
    border-radius: 8px;
    padding: 20px;
}

/* #dashboard-page #Categories .project h2 {
	color: var(--theme-color);
	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
	padding-bottom: 4px;
	border-bottom: 1px dashed var(--navigation-text-color-all-heading-color-main-heading-line-color, #848484);
	border-bottom: 1px dashed #848484;
	display: inline;
	font-family: "Roboto";
} */

#dashboard-page #Categories .project .body {
    margin-top: 16px;
}

#dashboard-page #Categories .project .body .form-groups {
    /* margin-right: 20px; */
    margin-right: 0px;
    margin-bottom: 8px;
    /* width: 100%; */
}

#role-main .colorCode-ant {
    /* margin-right: 20px; */
    margin-right: 13px;
    width: 100px !important;
}

/* #dashboard-page #Categories .project .body .form-groups label {
	font-size: 15px;
	color: var(--heading-color);
	font-weight: 400;
	font-family: 'Roboto';
	font-style: normal;
	line-height: 22px;
} */

#dashboard-page #Categories .project .body .form-groups .form-control {
    border-radius: 6px;
    border: 1px solid #D4D4D4;
    padding: 5px 12px;
    margin-top: 8px;
    box-shadow: none;
    transition: 0.3s;
    color: #333;
    height: 32px;
    font-size: 14px;
    font-weight: 500;
}

.image-pdf-logo {
    width: 36px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 4px;
}

#dashboard-page #Categories .project .body .form-groups .form-control::placeholder {
    color: #888;
    font-weight: 400;
}

#dashboard-page #Categories .project .body .form-groups .form-control:focus {
    border-color: var(--theme-color);
}

#dashboard-page #Categories .project .body .form-groups.sname {
    /* max-width: 135px; */
    max-width: 181px;
}

#dashboard-page #Categories .project .body .form-groups.name {
    max-width: 285px;
}

#dashboard-page #Categories .project .body .form-groups.pname {
    max-width: 440px;
}

#dashboard-page #Categories .project .body .form-groups.platform {
    /* max-width: 125px; */
    max-width: 179px;
}

#dashboard-page #Categories .project .body .form-groups .btn {
    background: var(--theme-color);
    color: #fff;
    padding: 2px 18px;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
    border: 1px solid var(--theme-color);
    border-radius: 6px;
    transition: 0.3s;
    font-size: 15px;
}

#dashboard-page #Categories .project .body .form-groups .form-control:focus {
    border-color: #1677FF;
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    outline: 0;
}

/*Project Records*/
#dashboard-page #Categories .project-records {
    margin-top: 10px;
    background: var(--white-color);
    border: 1px solid var(--border-dadcdf);
    border-radius: 8px;
    padding: 20px;
}

#dashboard-page #Categories .project-records .header .left h2 {
    padding-bottom: 4px;
    border-bottom: 1px dashed #848484;
    display: inline;
    color: var(--navigation-text-color-all-heading-color-all-main-heading-color, #3066FB);
    font-family: "Roboto";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 133.333% */
}

#dashboard-page #Categories .project-records .header .right label {
    color: var(--heading-color);
    font-family: "Roboto";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

#dashboard-page #Categories .project-records .header .right .form-groups {
    margin-right: 16px;
    width: 100%;
    position: relative;
}

#dashboard-page #Categories .project-records .header .right .form-groups i {
    position: absolute;
    /* top: 60%; */
    top: 87%;
    transform: translateY(-85%);
    right: 14px;
}

#dashboard-page #Categories .project-records .header .right .form-groups .form-control {
    margin-top: 8px;
    border-radius: 6px;
    border: 1px solid #D4D4D4;
    padding: 7px 14px;
    color: #333;
    font-size: 14px;
    font-weight: 500;
    transition: 0.3s;
    box-shadow: none;
}

#dashboard-page #Categories .project-records .header .right .form-groups .form-control::placeholder {
    color: #888;
    font-size: 14px;
    font-weight: 400;
}

#dashboard-page #Categories .project-records .header .right .form-groups .form-control:focus {
    border-color: var(--theme-color);
}

#dashboard-page #Categories .project-records .header .right .form-groups.platform,
#dashboard-page #Categories .project-records .header .right .form-groups.status {
    /* max-width: 125px; */
    max-width: 162px;
}

#dashboard-page #Categories .project-records .header .right .form-groups.searchSubCategory {
    max-width: 290px;
}

#dashboard-page #Categories .project-records .header .right .form-groups.search {
    max-width: 350px;
}

#dashboard-page #Categories .project-records .header .right .form-groups .search-icon {
    right: 10px;
}

#dashboard-page #Categories .project-records .body {
    margin-top: 20px !important;
}

@media (max-width: 767px) {
    .custom-height {
        height: 30px;
    }

    #dashboard-page #Categories .col-lg-9.header-custom-col {
        width: 100%;
        overflow-y: auto;
    }

    #dashboard-page #Categories .project {
        padding: 12px;
    }

    /* #dashboard-page #Categories .project h2 {
		font-size: 14px;
	} */

    #dashboard-page #Categories .project .body .form-groups {
        max-width: 100% !important;
        margin-top: 12px;
    }

    /* #dashboard-page #Categories .project .body .form-groups label {
		font-size: 12px;
	} */

    #dashboard-page #Categories .project .body .form-groups .form-control {
        font-size: 12px;
    }

    #dashboard-page #Categories .project .body {
        margin-top: 5px;
    }

    #dashboard-page #Categories .project-records {
        padding: 12px;
    }

    #dashboard-page #Categories .project-records .header .left h2 {
        font-size: 14px;
    }

    #dashboard-page #Categories .project-records .header .right .form-groups {
        max-width: 100% !important;
        margin-top: 12px;
    }

    #dashboard-page #Categories .project-records .header .right label {
        font-size: 12px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    #dashboard-page #Categories .col-lg-9.header-custom-col {
        width: 100%;
    }

    #dashboard-page #Categories .project {
        padding: 16px;
    }

    #dashboard-page #Categories .project-records {
        padding: 16px;
    }

    #dashboard-page #Categories .project .body {
        margin-top: 20px;
    }

    /* #dashboard-page #Categories .project h2 {
		font-size: 16px;
	} */

    /* #dashboard-page #Categories .project .body .form-groups label {
		font-size: 13px;
	} */

    #dashboard-page #Categories .project-records .header .left h2 {
        font-size: 16px;
    }

    #dashboard-page #Categories .project-records .header .right label {
        font-size: 13px;
    }

    #dashboard-page #Categories .project-records .body {
        margin-top: 20px;
    }

    #dashboard-page #Categories .project-records .header .right .form-groups.platform,
    #dashboard-page #Categories .project-records .header .right .form-groups.status {
        /* max-width: 80px; */
        max-width: 100px;
    }
}

@media (min-width: 992px) and (max-width: 1500px) {
    #dashboard-page #Categories .project {
        padding: 10px;
    }

    /* #dashboard-page #Categories .project h2 {
		font-size: 14px;
	} */

    #dashboard-page #Categories .project .body {
        margin-top: 20px;
    }

    /* #dashboard-page #Categories .project .body .form-groups label {
		font-size: 15px;
	} */

    #dashboard-page #Categories .project .body .form-groups .form-control {
        padding: 4px 10px;
        font-size: 12px;
        height: 34px;
    }

    #dashboard-page #Categories .project-records {
        padding: 20px 10px;
    }

    #dashboard-page #Categories .project-records .header .left h2 {
        font-size: 14px;
    }

    #dashboard-page #Categories .project-records .header .right label {
        font-size: 12px;
    }

    #dashboard-page #Categories .project-records .header .right .form-groups .form-control {
        padding: 5px 7px;
        font-size: 12px;
    }

    #dashboard-page #Categories .project-records .header .right .form-groups.platform,
    #dashboard-page #Categories .project-records .header .right .form-groups.status {
        /* max-width: 80px; */
        max-width: 125px;
    }

    #dashboard-page #Categories .project-records .header .right .form-groups.searchSubCategory {
        max-width: 280px;
    }

    #dashboard-page #Categories .project-records .header .right .form-groups {
        margin-right: 15px;
    }

    #dashboard-page #Categories .project-records .body {
        margin-top: 14px;
    }
}

.custom-width {
    /* margin-right: 20px; */
    margin-right: 13px;
    /* width: 100%; */
}

.notification-Campaign .ant-modal {
    width: 20% !important;
    min-width: 300px;
}

.notification-Campaign .ant-form-item {
    margin-bottom: 8px;
}

.notification-Campaign .ant-form-item-label {
    padding-bottom: 3px;
}

.notification-Campaign .date-mod .ant-picker {
    width: 200px;
}

/* .notification-Campaign .ant-picker {
	width: 170px;
} */

.modal-component-time-picker .ant-picker-ok {
    margin-left: 5px !important;
}

.action-res {
    gap: 8px;
}

/* user Notifiction Data CSS */

.design-app {
    display: flex;
    gap: 5px;
}

.secound-child {
    color: #3066fb;
    font-weight: 500;
}

.secound-child-two {
    gap: 5px;
}

.design-main {
    gap: 10px !important;
}

.design-main .h2 {
    height: 29px !important;
}

.label-logo {
    font-weight: 500;
    color: #00000073;
}

.label-logo span {
    color: black;
}
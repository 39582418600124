:root {
    --primary-color: #3066FB;
    --border-color: #848484;
    --font-size-title: 18px;
}

#Manage-Profile .mange-profile-body .form-group .form-control:focus {
    border-color: #1677FF;
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    outline: 0;
}

#Manage-Profile .mange-profile-body .form-group .form-control {
    border-radius: 6px;
    border: 1px solid #D4D4D4;
    padding: 8px 14px;
    margin-top: 8px;
    box-shadow: none;
    transition: 0.3s;
    color: #333;
    height: 35px;
    font-size: 14px;
    font-weight: 500;
}

#Manage-Profile .mange-profile-body .form-group label {
    font-size: 15px;
    color: var(--heading-color);
    font-weight: 400;
    font-family: 'Roboto';
    font-style: normal;
    line-height: 22px;
}

#Manage-Profile .mange-profile-input-body .row {
    margin-top: 16px;
}

#Manage-Profile .tab-mange-profile .tab-svg {
    padding: 0px 10px 0 0px;
}

#Manage-Profile .mange-profile-input-body {
    width: 100%;
}

#Manage-Profile .candidate-details {
    width: 100%;
}

#Manage-Profile .mange-profile-input-body {
    margin-top: 7px;
}

#Manage-Profile .form-control:focus {
    border-color: none;
    box-shadow: none;
}

#Manage-Profile .mange-profile-body .candidate-details .ant-form-item {
    width: 300px;
}

#Manage-Profile .mange-profile-body .candidate-details .ant-picker {
    margin-top: 8px;
    width: 100%;
    padding: 7px 11px 4px;
}

#Manage-Profile .mange-profile-body .candidate-details .address-textarea {
    width: 615px;
    padding: 8px 0px 0px;
}

#Manage-Profile .mange-profile-body .candidate-details .height-set-Description {
    max-width: 202px;
}

#Manage-Profile .mange-profile-body .candidate-details .upload-p-photo {
    display: flex;
    padding: 5px 16px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    width: 60%;
}

#Manage-Profile .mange-profile-body .candidate-details .ant-upload-list-item {
    max-width: 200px;
}

#Manage-Profile .mange-profile-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

#Manage-Profile .mange-profile-footer .ant-btn-primary{
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    display: flex;
    padding: 6px 30px;
    align-items: center;
}

#Manage-Profile .mange-profile-footer .mange-profile-btn {
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    display: flex;
    padding: 6px 30px;
    align-items: center;
}

#Manage-Profile .mange-profile-footer .mange-profile-btn:first-child {
    color: #969696;
}

#Manage-Profile .mange-profile-footer .mange-profile-btn:hover {
    border-color: #d9d9d9 !important;
}

#Manage-Profile .mange-profile-body .candidate-details .uploads-btn {
    border-color: #d9d9d9 !important;
    color: rgba(0, 0, 0, 0.88) !important;
    width: 185px;
}

#Manage-Profile .mange-profile-body .candidate-details .upload-image-profile {
    width: 185px;
}

#Manage-Profile .mange-profile-body .candidate-details .profile-image-btn {
    height: 112px;
    display: flex;
    align-items: center;
}

#Manage-Profile .mange-profile-body .form-control:disabled  {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: rgba(0, 0, 0, 0.04) !important;
    border-color: #d9d9d9;
    box-shadow: none;
}

.image-model .ant-modal-content{
    padding: 24px 24px;
}

.image-model .ant-modal-close {
    position: absolute;
    top: 0px !important;
    left: 95% !important;
    right: 0px;
    inset-inline-end: 17px;
    z-index: 1010;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 600;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border-radius: 4px;
    width: 22px;
    height: 22px;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.2s, background-color 0.2s;
}

@media screen and (min-width: 1025px) and (max-width: 1600px) {
    #Manage-Profile .mange-profile-input-body .candidate-details .candidate-details-child {
        flex-wrap: wrap;
    }
}


@media screen and (min-width: 991px) and (max-width: 1025px) {
    #Manage-Profile .mange-profile-input-body .candidate-details .candidate-details-child {
        flex-wrap: wrap;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    #Manage-Profile .mange-profile-input-body .candidate-details .candidate-details-child {
        display: flex;
        flex-wrap: wrap;
    }

    #Manage-Profile .mange-profile-input-body .candidate-details .address-info-mange-profile {
        display: flex;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 768px) {
    #Manage-Profile .mange-profile-body .candidate-details .ant-form-item {
        width: 100%;
    }

    #Manage-Profile .mange-profile-body .candidate-details .height-set-Description {
        max-width: 100%;
        width: 100%;
    }

    #Manage-Profile .mange-profile-body .form-group {
        max-width: 100% !important;
        margin-top: 12px !important;
    }
}


/* Setting Css */
#Manage-Profile .main-setting .setting-input .time-Zone {
    width: 285px;
}

#Manage-Profile .main-setting .setting-input .menu-setting-profile {
    width: 185px;
}


@media screen and (min-width: 767px) and (max-width: 991px) {
    #Manage-Profile .main-setting .setting-input .setting-input-select {
        display: flex;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 767px) {
    #Manage-Profile .main-setting .setting-input .time-Zone {
        width: 100%;
    }

    #Manage-Profile .main-setting .setting-input .menu-setting-profile {
        width: 100%;
    }
}



/*Project Records*/
#Manage-Profile.project-records {
    /* margin-top: 10px; */
    background: var(--white-color);
    border: 1px solid var(--border-dadcdf);
    border-radius: 8px;
    padding: 20px;
}


#Manage-Profile.project-records .header .right .form-groups {
    margin-right: 16px;
    width: 100%;
    position: relative;
}

#Manage-Profile.project-records .header .right .form-groups i {
    position: absolute;
    /* top: 60%; */
    top: 87%;
    transform: translateY(-85%);
    right: 14px;
}

#Manage-Profile.project-records .header .right .form-groups .form-control {
    margin-top: 8px;
    border-radius: 6px;
    border: 1px solid #D4D4D4;
    padding: 7px 14px;
    color: #333;
    font-size: 14px;
    font-weight: 500;
    transition: 0.3s;
    box-shadow: none;
}

#Manage-Profile.project-records .header .right .form-groups .form-control::placeholder {
    color: #888;
    font-size: 14px;
    font-weight: 400;
}

#Manage-Profile.project-records .header .right .form-groups .form-control:focus {
    border-color: var(--theme-color);
}

#Manage-Profile.project-records .header .right .form-groups.platform,
#Manage-Profile.project-records .header .right .form-groups.status {
    /* max-width: 125px; */
    max-width: 162px;
}

#Manage-Profile.project-records .header .right .form-groups.searchSubCategory {
    max-width: 290px;
}

#Manage-Profile.project-records .header .right .form-groups.search {
    max-width: 350px;
}

#Manage-Profile.project-records .header .right .form-groups .search-icon {
    right: 10px;
}

#Manage-Profile.project-records .body {
    margin-top: 20px !important;
}

@media (max-width: 767px) {
    .custom-height {
        height: 30px;
    }

    #Manage-Profile .col-lg-9.header-custom-col {
        width: 100%;
        overflow-y: auto;
    }

    #Manage-Profile .project {
        padding: 12px;
    }

    /* #Manage-Profile .project h2 {
		font-size: 14px;
	} */

    #Manage-Profile .project .body .form-groups {
        max-width: 100% !important;
        margin-top: 12px;
    }

    /* #Manage-Profile .project .body .form-groups label {
		font-size: 12px;
	} */

    #Manage-Profile .project .body .form-groups .form-control {
        font-size: 12px;
    }

    #Manage-Profile .project .body {
        margin-top: 5px;
    }

    #Manage-Profile.project-records {
        padding: 12px;
    }

    #Manage-Profile.project-records .header .left h2 {
        font-size: 14px;
    }

    #Manage-Profile.project-records .header .right .form-groups {
        max-width: 100% !important;
        margin-top: 12px;
    }

    #Manage-Profile.project-records .header .right label {
        font-size: 12px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    #Manage-Profile .col-lg-9.header-custom-col {
        width: 100%;
    }

    #Manage-Profile .project {
        padding: 16px;
    }

    #Manage-Profile.project-records {
        padding: 16px;
    }

    #Manage-Profile .project .body {
        margin-top: 20px;
    }

    /* #Manage-Profile .project h2 {
		font-size: 16px;
	} */

    /* #Manage-Profile .project .body .form-groups label {
		font-size: 13px;
	} */

    #Manage-Profile.project-records .header .left h2 {
        font-size: 16px;
    }

    #Manage-Profile.project-records .header .right label {
        font-size: 13px;
    }

    #Manage-Profile.project-records .body {
        margin-top: 20px;
    }

    #Manage-Profile.project-records .header .right .form-groups.platform,
    #Manage-Profile.project-records .header .right .form-groups.status {
        /* max-width: 80px; */
        max-width: 100px;
    }
}

@media (min-width: 992px) and (max-width: 1500px) {
    #Manage-Profile .project {
        padding: 10px;
    }

    #Manage-Profile.project-records {
        padding: 20px 10px;
    }

    #Manage-Profile.project-records .header .left h2 {
        font-size: 14px;
    }

    #Manage-Profile.project-records .header .right label {
        font-size: 12px;
    }

    #Manage-Profile.project-records .header .right .form-groups .form-control {
        padding: 5px 7px;
        font-size: 12px;
    }

    #Manage-Profile.project-records .header .right .form-groups.platform,
    #Manage-Profile.project-records .header .right .form-groups.status {
        /* max-width: 80px; */
        max-width: 125px;
    }

    #Manage-Profile.project-records .header .right .form-groups.searchSubCategory {
        max-width: 280px;
    }

    #Manage-Profile.project-records .header .right .form-groups {
        margin-right: 15px;
    }

    #Manage-Profile.project-records .body {
        margin-top: 14px;
    }
}
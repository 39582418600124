#cart-main {
    border-radius: 8px;
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 12px 20px;
}

#cart-main .place-order-button {
    /* display: flex;
    justify-content: end; */
    /* margin-top: 10px; */
}

.custom-table-fs .table-bg>th {
    background-color: #f8f9fa;
}

#cart-main .cart-main-title {
    font-weight: 500;
    font-size: 20px;
    /* line-height: 36px; */
    color: #3C4043;
    letter-spacing: 0.2px;
    /* margin-bottom: 16px; */
    /* box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px; */
    border-bottom: 1px solid #E7E7E7;
    padding-bottom: 8px;
    margin-bottom: 2px;
}

#cart-main .cart-all-data>.cart-details {
    padding: 16px 0;
    border-bottom: 1px solid #E7E7E7;
}

#cart-main .cart-all-data>.cart-details:last-child {
    padding: 16px 0;
    border-bottom: none;
}

/* #cart-main .cart-all-data>.cart-details:last-child {
    border-bottom: 1px solid #E7E7E7;
} */


#cart-main .cart-all-data>.cart-details .cover-image {
    height: 180px;
    width: 180px;
    padding-right: 8px;
    max-width: 100%;
    overflow: hidden;
}

#cart-main .cart-all-data>.cart-details .cover-image img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    transition: all 0.2s ease-in-out;
}

#cart-main .cart-all-data>.cart-details .cover-image img:hover {
    transform: scale(0.96);
}

#cart-main .cart-all-data>.cart-details .details .list-product {
    font-size: 14px;
    color: #878787;
    margin-top: 6px;
}

#cart-main .cart-all-data>.cart-details .details .detail-title {
    font-size: 18px;
    /* color: #0F1111; */
    letter-spacing: 0.1px;
    word-break: normal;
    text-transform: capitalize;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#cart-main .cart-all-data>.cart-details .details .stock.red {
    font-size: 12px;
    color: red;
}

#cart-main .cart-all-data>.cart-details .details .stock.green {
    font-size: 12px;
    color: #007600;
}

#cart-main .cart-all-data {
    min-height: 300px;
}

#cart-main .cart-all-data>.cart-details .details .label-small-text {
    font-size: 14px;
    /* margin-top: 2px; */
}

#cart-main .cart-all-data>.cart-details .details .label-small {
    font-weight: 500;
    color: #0F1111;
}

#cart-main .cart-all-data>.cart-details .price {
    display: flex;
    gap: 5px;
    height: max-content;
    align-items: center;
    margin-top: 4px;
    flex-wrap: wrap;
}

#cart-main .cart-all-data>.cart-details .price .dark {
    color: #0F1111;
    font-weight: 500;
    font-size: 18px;
}

#cart-main .cart-all-data .ant-empty-description {
    font-size: 20px;
    font-weight: 500;
}

#cart-main .cart-all-data>.cart-details .price .light {
    color: #0F1111;
    font-weight: 400;
    font-size: 12px;
}

#cart-main .cart-all-data>.cart-details .cover-image a {
    display: inline-block;
    height: 100%;
    width: 100%;
}

#cart-main .cart-all-data>.cart-details .cover-image .ant-skeleton {
    width: 100%;
    height: 100%;
}

#cart-main .cart-all-data>.cart-details .cover-image .ant-skeleton .ant-skeleton-image {
    width: 100%;
    height: 100%;
}

#cart-main .cart-all-data>.cart-details .cover-image .ant-skeleton .ant-skeleton-image>span {
    display: none;
}

#cart-main .cart-footer {
    padding: 8px 0 0 0;
    gap: 5px;
    border-top: 1px solid #E7E7E7;
    margin-top: 2px;
}

/* #cart-main .cart-footer .form-custom {
    display: flex;
    align-items: center;
    justify-content: space-between;
} */

#cart-main .cart-footer .description {
    padding-right: 10px;
}

#cart-main .cart-footer .description .ant-form-item {
    margin-bottom: 10px;
}

#cart-main .cart-footer .cart-footer-right {
    display: flex;
    flex-direction: column;
    gap: 6px;
    justify-content: end;
}

#cart-main .cart-footer .cart-footer-right>div {
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
}

#cart-main .cart-footer .price {
    color: #0F1111;
    font-weight: 500;
    font-size: 18px;
    font-size: 'Roboto';
}

#cart-main .cart-footer .subtotal {
    color: #0F1111;
    font-weight: 400;
    font-size: 18px;
    font-size: 'Roboto';
    letter-spacing: 0.1px;
}

#cart-main .order-detail-data {
    /* margin: 10px 0px; */
    margin-top: 5px;
}

.light-txt {
    color: #878787;
    font-weight: 500;
    /* font-size: 14px; */
}

#cart-main .order-detail-data .custom-table-fs {
    font-size: 15px;
    text-transform: capitalize;
    text-wrap: nowrap;
}

#cart-main .order-detail-data .custom-table-fs .custom-width-index {
    width: 30px;
}


#cart-main .order-detail-data .custom-table-fs .custom-width {
    width: 100px;
}

#cart-main .order-detail-data .custom-table-fs .custom-width-total {
    width: 110px;
}

#cart-main .order-detail-data .custom-table-fs td,
#cart-main .order-detail-data .custom-table-fs th {
    padding: 6px 8px;
}


@media screen and (max-width:991px) {}

@media screen and (max-width: 767px) {
    #cart-main .cart-all-data>.cart-details .cover-image {
        height: 150px;
        width: 150px;
    }

    #cart-main .cart-main-title {
        font-size: 18px;
    }

    #cart-main .cart-all-data>.cart-details .details .detail-title {
        font-size: 16px;
    }

    #cart-main .cart-all-data>.cart-details .price .dark {
        font-size: 16px;
    }

    #cart-main .cart-all-data>.cart-details .price .light {
        font-size: 12px;
    }

    #cart-main .cart-all-data>.cart-details .details .label-small-text {
        font-size: 13px;
    }

    #cart-main .cart-footer .price {
        font-size: 16px;
    }

    #cart-main .cart-footer .subtotal {
        font-size: 16px;
    }

    /* #cart-main .cart-main-title {
        margin-bottom: 10px;
    } */

    #cart-main {
        padding: 18px;
    }

    #cart-main .order-detail-data .custom-table-fs {
        font-size: 14px;
    }
}

@media screen and (max-width: 575px) {
    #cart-main .cart-main-title {
        font-size: 15px;
    }

    #cart-main .cart-all-data>.cart-details .cover-image {
        height: 120px;
        width: 120px;
        /* height: 100px;
        width: 100px; */
    }

    #cart-main .cart-all-data>.cart-details .details .detail-title {
        font-size: 13px;
    }

    #cart-main .cart-all-data>.cart-details .price .dark {
        font-size: 14px;
    }

    #cart-main .cart-all-data>.cart-details .price .light {
        font-size: 12px;
    }

    #cart-main .cart-all-data>.cart-details .details .label-small-text {
        font-size: 13px;
    }

    #cart-main .cart-footer .price {
        font-size: 14px;
    }

    #cart-main .cart-footer .subtotal {
        font-size: 14px;
    }

    #cart-main .cart-main-title {
        margin-bottom: 10px;
    }

    #cart-main {
        padding: 15px;
    }

    #cart-main .order-detail-data .custom-table-fs {
        font-size: 13px;
    }

    #cart-main .order-detail-data .custom-table-fs td,
    #cart-main .order-detail-data .custom-table-fs th {
        padding: 5px 7px;
    }
}
#navbar {
    .navbar-right {
        .profile {
            width: 50px;

            img {
                width: 100%;
            }
        }

        .mobile-nav-toggler .menu-icon {
            font-size: 25px;
            margin-right: 10px;
            transition: 0.3s;
        }
    }

    .navbar-left {
        h3 {
            margin: 0.25rem 0;
        }
    }
}

.profile-img {
    height: 2.25rem;
    width: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 100%;
    border: 1px solid transparent;
    transition: 0.3s;
    cursor: pointer;
}
.profile-img img{
    height: 36px;
}
.details-menu-top-menu {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
}

// .details-menu-top-menu>.svg-style {
//     width: 20px;
//     height: 20px;
// }

.details-menu-top-menu>span {
    color: #3c4043;
    text-align: center;
    align-self: stretch;
    /* Side Navigation style/Body text 2 */
    font-family: 'Roboto';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    align-items: center;
    display: flex;
}


@media (min-width: 992px) {
    #navbar {
        .navbar-right {
            .mobile-nav-toggler .menu-icon {
                display: none;
            }
        }
    }
}
#login-page{
	overflow: hidden;
}
#login-page main .main-img{
	height: 100vh;
	width: 100%;
	object-fit: cover;
}
#login-page main .content .logo{
	margin-bottom: 20px;
}
#login-page main .content .logo img{
	max-width: 200px;
}
#login-page main .content{
	max-width: 500px;
	margin: auto;
}
#login-page main .content h2{
	margin-top: 30px;
	font-size: 24px;
	font-weight: 600;
	color: var(--heading-color);
	position: relative;
	display: inline;
	padding-bottom: 15px;
}
#login-page main .content h2:before{
	content: '';
	position: absolute;
	height: 18px;
	width: 180px;
	background: url('../images/heading-line.png');
	background-repeat: no-repeat;
	right: 10%;
	bottom: 0;
	background-position: right;
}
#login-page main .content p{
	font-size: 16px;
	color: var(--heading-color);
	margin-top: 9px;
}
#login-page main .content .form-group{
	margin-top: 12px;
}
#login-page main .content .form-group label{
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
}
#login-page main .content .form-group input{
	margin-top: 8px;
	height: 42px;
	border-radius: 4px;
	border: 1px solid var(--neutral);
	padding: 10px 14px;
	transition: 0.3s;
	box-shadow: none;
}
#login-page main .content .form-group input:focus{
	border-color: var(--theme-color);
}
#login-page main .content .form-group input::placeholder{
	font-size: 14px;
	color: var(--placeholder);
}
#login-page main .content .rpassword{
	margin-top: 9px;
}
#login-page main .content .rpassword a{
	color: var(--theme-color);
	font-size: 14px;
	line-height: 22px;
}
#login-page main .content .otp .btn{
	padding: 8px 10px;
	background: var(--theme-color);
	color: #fff;
	margin-left: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
}
#login-page main .content .otp input{
	max-width: 150px;
	text-align: center;
}
#login-page main .content .resend{
	margin-top: 9px;
	color: var(--theme-color);
}
#login-page main .content .resend a{
	font-size: 14px;
}
#login-page main .content .form-check{
	margin-top: 20px;
}
#login-page main .content .form-check input{
	height: 16px;
	width: 16px;
}
#login-page main .content .form-check label{
	font-size: 14px;
	color: rgba(0, 0, 0, 0.85);
}
#login-page main .content .login-btn{
	width: 100%;
	height: 39px;
	/* height: 46px; */
	background: var(--theme-color);
	color: #fff;
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	font-weight: 700;
	border: 1px solid var(--theme-color);
}
#login-page main .content .login-btn:hover{
	background: transparent;
	color: var(--theme-color);
}
#login-page main .content .or{
	text-align: center;
	margin-top: 27px;
	position: relative;
}
#login-page main .content .or:before{
	content: '';
	position: absolute;
	height: 1px;
	width: 100%;
	background: #DADCDF;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	z-index: -1;
}
#login-page main .content .or p{
	color: #817E8D;
	font-size: 16px;
	background: #fff;
	display: inline;
	padding: 0 10px;
}
#login-page main .content .google{
	border: 1px solid #DADCDF;
	width: 100%;
	/* padding: 12px 14px; */
	padding: 7px 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 16px;
	position: relative;
	border-radius: 12px;
	font-weight: 500;
	font-size: 16px;
}
#login-page main .content .google img{
	position: absolute;
	left: 15px;
}
#login-page main .content .not{
	margin-top: 8px;
}
#login-page main .content .not p{
	color: var(--heading-color);
	/* font-size: 18px; */
	font-size: 15px;
	font-weight: 500;
}
@media (max-width: 767px) {
	#login-page main {
		width: 100%;
	}
	#login-page main .main-img{
		display: none;
	}
	#login-page main .content .logo{
		margin-bottom: 16px;
	}
	#login-page{
		height: 100vh;
		display: flex;
		align-items: center;
	}
	#login-page main .content h2{
		margin-top: 15px;
		font-size: 18px;
	}
	#login-page main .content p{
		font-size: 14px;
	}
	#login-page main .content .form-group{
		margin-top: 8px;
	}
	#login-page main .content .form-group label{
		font-size: 12px;
	}
	#login-page main .content .form-group input{
		margin-top: 4px;
	}
	#login-page main .content .rpassword a{
		font-size: 12px;
	}
	#login-page main .content .resend a{
		font-size: 12px;
	}
	#login-page main .content .form-check{
		margin-top: 10px;
	}
	#login-page main .content .login-btn{
		font-size: 16px;
		height: 40px;
	}
	#login-page main .content .otp .btn{
		font-size: 16px;
	}
	#login-page main .content .or{
		margin-top: 15px;
	}
	#login-page main .content .or p{
		font-size: 14px;
	}
	#login-page main .content .google{
		margin-top: 10px;
		font-size: 14px;
		height: 40px;
	}
	#login-page main .content .not p{
		font-size: 12px;
	}
	#login-page main .content .logo img{
		max-width: 150px;
	}
	#login-page{
		overflow: auto;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	#login-page main .content .logo{
		margin-bottom: 16px;
	}
	#login-page main .content h2{
		font-size: 20px;
	}
	#login-page main .content p{
		font-size: 12px;
	}
	#login-page main .content .form-group{
		margin-top: 10px;	
	}
	#login-page main .content .form-group label{
		font-size: 12px;
	}
	#login-page main .content .rpassword a{
		font-size: 12px;
	}
	#login-page main .content .otp .btn{
		padding: 8px;
	}
	#login-page main .content .resend{
		font-size: 12px;
	}
	#login-page main .content .form-check label{
		font-size: 12px;
	}
}
@media (min-width: 992px) and (max-width: 1400px) {
	#login-page main .content .logo{
		margin-bottom: 15px;
	}
	#login-page main .content .logo img{
		max-width: 150px;
	}
	#login-page main .content h2{
		font-size: 20px;
	}
	#login-page main .content p{
		font-size: 14px;
	}
	#login-page main .content .form-group{
		margin-top: 6px;
	}
	#login-page main .content .form-group input{
		height: 38px;
	}
	#login-page main .content .form-group input{
		margin-top: 4px;
	}
	#login-page main .content .form-check{
		margin-top: 10px;
	}
	#login-page main .content .or{
		margin-top: 15px;
	}
	#login-page main .content .or p{
		font-size: 14px;
	}
	#login-page main .content .google{
		margin-top: 10px;
		font-size: 14px;
	}
	#login-page main .content .login-btn{
		height: 42px;
	}
	#login-page main .content .not p{
		font-size: 14px;
	}
}
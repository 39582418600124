:root {
    --font-size-17: 17px;
}

#home-page {
    /* overflow-y: auto; */
}

#home-page .category-card {
    display: flex;
    padding-bottom: 12px;
    flex-direction: column;
    align-items: center;
    flex: 1 0 0;
    border-radius: 12px;
    background: #F9F9F9;
}

#home-page .category-body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 24px;
    column-gap: 16px;
}

#home-page .category-body>a {
    color: initial;
}

#home-page .category-card .img-card {
    text-align: center;
    display: flex;
    width: 100%;
    height: 220px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

#home-page .category-card .img-card .ant-skeleton {
    height: 95%;
    width: 100%;
}

#home-page .category-card .img-card .ant-skeleton>div {
    height: 100%;
    width: 100%;
    border-radius: 12px;
}

#home-page .category-card .img-card .ant-skeleton .ant-skeleton-image>span {
    display: none;
}

#home-page .category-card .img-card>img {
    height: 100%;
    width: 100%;
    object-fit: cover;

    flex-shrink: 0;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.02);
}

#home-page .category-card .img-title {
    display: flex;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
}

#home-page .category-card .img-title>span {
    color: var(--all-text-color-Body-text, #3C4043);
    text-align: center;
    font-family: 'Roboto';
    font-size: var(--font-size-17);
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 141.176% */
}

.loading-image {
    width: 100%;
    height: 100%;
}

.loading-image .ant-spin-text {
    color: #3C4043;
}

.loading-image .ant-spin-dot-item {
    background-color: #3C4043;
}


@media screen and (max-width: 1024px) {
    #home-page .category-body {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (max-width:991px) {
    #home-page .category-body {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 767px) {
    /* #home-page .category-body {
        grid-template-columns: repeat(3, 1fr);
    } */
}

@media screen and (max-width: 575px) {
    :root {
        --font-size-17: 14px;
    }

    #home-page .category-body {
        grid-template-columns: repeat(2, 1fr);
    }

    #home-page .category-card .img-card {
        height: 180px;
    }

    #home-page .category-card .img-card {
        padding: 8px;
    }

    #home-page .category-card {
        padding-bottom: 8px;
    }

    #home-page .category-body {
        row-gap: 18px;
        column-gap: 12px;
    }
}
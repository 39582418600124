#order {
    background: var(--white-color);
    border: 1px solid var(--border-dadcdf);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 10px;
}

#order .order-title {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.2px;
    color: #3C4043;
    margin-bottom: 16px;
}

#order .button-option {
    cursor: pointer;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    /* padding: 5px; */
    transition: all 0.3s ease-in-out;
}

#order .button-option:hover {
    background-color: #E0E0E0;
}

#order .red-user{
    width: 90px;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 6px;
    background: #FDECEB;
    cursor: pointer;
}
#order .red-text-user{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #FF6A6A !important;
}

#order .orange-user{
    width: 90px;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 6px;
    background: #FCF6E1;
    cursor: pointer;
}
#order .orange-text-user{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #EC9100 !important;
}

#order .green-user{
    width: 90px;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 6px;
    background: #DFF4E7;
    cursor: pointer;
}
#order .green-text-user{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #29B85F !important;
}

#order .blue-user{
    width: 90px;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 6px;
    background: #CFE3FC;
    cursor: pointer;
}
#order .blue-text-user{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #3066FB !important;
}
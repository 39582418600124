:root {
    --common-3-transition: all 0.2s ease-in-out;
    --fs-15-head: 15px;
    --fs-14-head: 14px;
}

#header-user {
    background-color: #1E1E1E;
    position: sticky;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.header-padding {
    padding-top: 102px;
}

#header-user .logo {
    width: 175px;
    height: 96px;
    max-height: 100%;

    flex-shrink: 0;
    background: url('../../App_Details_theme/images/main/logo-white-theme.png') 50% / contain no-repeat;
    align-self: center;
}

#header-user>div {
    display: flex;
    gap: 30px;
}

#header-user>div .details-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    position: relative;
}

#header-user>div .details-menu .details-menu-top {
    display: flex;
    padding: 10px 26px;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    background-color: #111;
}

#header-user>div .details-menu .details-menu-top>.details-menu-top-title {
    color: #FFF;
    font-family: 'Roboto';
    font-size: var(--fs-15-head);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

#header-user>div .details-menu .details-menu-top>.details-menu-top-menu-all {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
}

#header-user>div .details-menu .details-menu-top>.details-menu-top-menu-all .details-menu-top-menu {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
}

.svg-style {
    width: 20px;
    height: 20px;
}

#header-user>div .details-menu .details-menu-top>.details-menu-top-menu-all .details-menu-top-menu {
    position: relative;
}

#header-user>div .details-menu .details-menu-top>.details-menu-top-menu-all .details-menu-top-menu .badge-custom {
    /* position: absolute;
    top: -5px;
    left: -10px; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 19px;
    width: 19px;
    background-color: red;
    border-radius: 50%;
    font-size: 12px !important;
}

#header-user>div .details-menu .details-menu-top>.details-menu-top-menu-all .details-menu-top-menu>span {
    color: #FFF;
    text-align: center;
    align-self: stretch;
    /* Side Navigation style/Body text 2 */
    font-family: 'Roboto';
    font-size: var(--fs-14-head);
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
}

#header-user>div .details-menu .details-menu-bottom {
    display: flex;
    align-items: center;
    gap: 32px;
    justify-content: space-between;
    align-self: stretch;
}

#header-user>div .details-menu .details-menu-bottom>.details-menu-bottom-left {
    display: flex;
    align-items: center;
}

#header-user>div .details-menu .details-menu-bottom>.details-menu-bottom-left .details-menu-bottom-left-button {
    display: flex;
    padding: 20px;
    align-items: center;
    gap: 4px;
    background-color: #1E1E1E;
    transition: var(--common-3-transition);
    cursor: pointer;
}

#header-user>div .details-menu .details-menu-bottom>.details-menu-bottom-left .details-menu-bottom-left-button>span {
    color: #FFF;

    /* All page text style/Body text 2 */
    font-family: 'Roboto';
    font-size: var(--fs-15-head);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    transition: var(--common-3-transition);

}

#header-user>div .details-menu .details-menu-bottom>.details-menu-bottom-left .details-menu-bottom-left-button>svg>path {
    fill: #FFF;
    transition: var(--common-3-transition);
}

#header-user>div .details-menu .details-menu-bottom>.details-menu-bottom-left .details-menu-bottom-left-button:hover {
    background-color: #FFF;
}

#header-user>div .details-menu .details-menu-bottom>.details-menu-bottom-left .details-menu-bottom-left-button.active {
    background-color: #FFF;
}

#header-user>div .details-menu .details-menu-bottom>.details-menu-bottom-left .details-menu-bottom-left-button:hover>span {
    color: #333;
}

#header-user>div .details-menu .details-menu-bottom>.details-menu-bottom-left .details-menu-bottom-left-button.active>span {
    color: #333;
}

#header-user>div .details-menu .details-menu-bottom>.details-menu-bottom-left .details-menu-bottom-left-button:hover>svg>path {
    fill: #333;
}

#header-user>div .details-menu .details-menu-bottom>.details-menu-bottom-left .details-menu-bottom-left-button.active>svg>path {
    fill: #333;
}

#header-user>div .details-menu .details-menu-bottom .details-menu-bottom-right {
    display: flex;
    align-items: center;
    /* gap: 10px; */
    flex: 1 0 0;
    border-radius: 6px;
    background: #414141;
    padding-left: 12px;
    position: relative;
}

#header-user>div .details-menu .details-menu-bottom .details-menu-bottom-right .search-box {
    background-color: #fff;
    padding: 10px;
    position: absolute;
    width: 100%;
    top: calc(100% + 10px);
    left: 0;
    border-radius: 6px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

#header-user>div .details-menu .details-menu-bottom .details-menu-bottom-right .search-box .title {
    /* display: block; */
    border-radius: 6px;
    padding: 4px 10px;
    transition: all 0.3s ease-in-out;
    font-size: 15px;
    color: #919191;
    font-weight: 500;
    font-family: 'Roboto';

    display: flex;
    align-items: center;
    gap: 10px;
}

#header-user>div .details-menu .details-menu-bottom .details-menu-bottom-right .search-box .title .img-logo {
    height: 30px;
    width: 30px;
}

#header-user>div .details-menu .details-menu-bottom .details-menu-bottom-right .search-box .title .img-logo>img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

#header-user>div .details-menu .details-menu-bottom .details-menu-bottom-right .search-box .title:hover {
    background-color: #F2F7FD;
    color: #3C4043;
}

#header-user>div .details-menu .details-menu-bottom .details-menu-bottom-right {
    cursor: pointer;
}

#header-user>div .details-menu .details-menu-bottom .details-menu-bottom-right>input {
    outline: none;
    border: none;
    background-color: inherit !important;
    padding: 12px 10px;
    width: 100%;
    height: 100%;

    color: #B2B2B2;
    font-family: 'Roboto';
    font-size: var(--fs-14-head);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 6px;
}

#header-user>div .details-menu .details-menu-bottom .details-menu-bottom-right>input::placeholder {
    color: #B2B2B2;
    font-family: 'Roboto';
    font-size: var(--fs-14-head);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

#header-user>div .details-menu .details-menu-hover {
    position: absolute;
    left: 0;
    top: 100%;
    width: 55%;
    /* width: 100%; */

    padding: 20px 12px;
    border-radius: 0px 0px 10px 10px;
    background: #FFF;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    gap: 32px;
    cursor: pointer;
}

#header-user>div .details-menu .details-menu-hover .details-menu-hover-left {
    padding: 0px 10px;
    max-height: 330px;
    overflow-y: auto;
}

#header-user>div .details-menu .details-menu-hover .details-menu-hover-left .items {
    display: flex;
    padding: 18px 0px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid #E9E9E9;
    cursor: pointer;
    gap: 30px;
    min-width: 130px;
}

#header-user>div .details-menu .details-menu-hover .details-menu-hover-left .items>svg {
    opacity: 0;
    transition: var(--common-3-transition);
}

#header-user>div .details-menu .details-menu-hover .details-menu-hover-left>.items:last-child {
    border-bottom: none;
}

#header-user>div .details-menu .details-menu-hover .details-menu-hover-left .items>span {
    color: #909090;
    font-family: 'Roboto';
    font-size: var(--fs-15-head);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.3px;
    transition: var(--common-3-transition);
    height: 20px;
}

#header-user>div .details-menu .details-menu-hover .details-menu-hover-left .items.active>span {
    color: #333;
    font-weight: 500;
}

#header-user>div .details-menu .details-menu-hover .details-menu-hover-left .items:hover>span {
    color: #333;
    font-weight: 500;
}

#header-user>div .details-menu .details-menu-hover .details-menu-hover-left .items:hover>svg {
    opacity: 1;
}

#header-user>div .details-menu .details-menu-hover .details-menu-hover-left .items.active>svg {
    opacity: 1;
}

#header-user>div .details-menu .details-menu-hover .details-menu-hover-right {
    max-height: 330px;
    overflow-y: auto;
}

#header-user>div .details-menu .details-menu-hover .details-menu-hover-right .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

#header-user>div .details-menu .details-menu-hover .details-menu-hover-right .details>.title {
    color: #333;
    font-family: 'Roboto';
    font-size: var(--fs-15-head);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

#header-user>div .details-menu .details-menu-hover .details-menu-hover-right .details>.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

#header-user>div .details-menu .details-menu-hover .details-menu-hover-right .details>.content>.items>a {
    color: #888;
    font-family: 'Roboto';
    font-size: var(--fs-14-head);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: var(--common-3-transition);
}

#header-user>div .details-menu .details-menu-hover .details-menu-hover-right .details>.content>.items:hover>a {
    color: #19790F;
}

@media screen and (max-width: 1199px) {
    #header-user>div .details-menu .details-menu-hover {
        width: 70%;
    }
}

@media screen and (max-width: 991px) {
    #header-user>div .details-menu .details-menu-hover {
        width: 80%;
    }
}

@media screen and (max-width: 768px) {
    :root {
        --fs-15-head: 14px;
        --fs-14-head: 13px;
    }

    #header-user>div {
        gap: 30px;
    }

    #header-user>div .details-menu .details-menu-bottom {
        gap: 11px;
    }

    #header-user>div .details-menu .details-menu-top>.details-menu-top-menu-all {
        gap: 18px;
    }

    #header-user>div .details-menu .details-menu-top {
        padding: 10px 18px;
    }

    .svg-style {
        height: 18px;
        width: 18px;
    }

    #header-user>div .details-menu .details-menu-bottom>.details-menu-bottom-left .details-menu-bottom-left-button {
        padding: 14px 18px;
    }

    #header-user .logo {
        width: 116px;
        height: 76px;
    }

    #header-user>div .details-menu .details-menu-hover .details-menu-hover-left .items {
        padding: 13px 0px;
    }

    #header-user>div .details-menu .details-menu-hover {
        padding: 16px 10px;
        width: 90%;
    }

    /* #header-user>div .details-menu .details-menu-bottom .details-menu-bottom-right .search-box {
        padding: 8px;
    } */

    #header-user>div .details-menu .details-menu-bottom .details-menu-bottom-right>input {
        padding: 12px 8px;
    }

    #header-user>div .details-menu .details-menu-bottom .details-menu-bottom-right {
        padding-left: 10px;
    }
}

@media screen and (max-width: 575px) {
    :root {
        --fs-15-head: 13px;
        --fs-14-head: 12px;
    }

    #header-user>div {
        gap: 20px;
    }

    #header-user>div .details-menu .details-menu-bottom {
        gap: 6px;
    }

    #header-user>div .details-menu .details-menu-top>.details-menu-top-menu-all {
        /* flex-direction: column; */
        gap: 10px;
    }

    #header-user>div .details-menu .details-menu-top {
        padding: 8px 12px;
        justify-content: flex-end;
    }

    .svg-style {
        width: 16px;
        height: 16px;
    }

    #header-user>div .details-menu .details-menu-bottom>.details-menu-bottom-left .details-menu-bottom-left-button {
        padding: 12px 10px;
    }

    #header-user>div .details-menu .details-menu-bottom .details-menu-bottom-right>input {
        padding: 9px 8px;
    }

    #header-user>div .details-menu .details-menu-top>.details-menu-top-title {
        display: none;
    }

    #header-user .logo {
        width: 56px;
        height: 56px;
        background: url('../../App_Details_theme/images/main/logo-white-theme.8e95ff921a8fb412db31.png') 50% / contain no-repeat;
    }


    #header-user>div .details-menu .details-menu-hover .details-menu-hover-left .items {
        padding: 8px 0px;
    }

    #header-user>div .details-menu .details-menu-hover {
        padding: 12px 10px;
        gap: 15px;
        width: 100%;
    }

    #header-user>div .details-menu .details-menu-bottom .details-menu-bottom-right .search-box {
        padding: 8px;
        gap: 4px;
    }

    #header-user>div .details-menu .details-menu-bottom .details-menu-bottom-right .search-box .title {
        font-size: 13px;
        padding: 3px 8px;
    }

    #header-user>div .details-menu .details-menu-hover .details-menu-hover-left {
        padding: 0px 5px;
    }
}
#product-main {
    border-radius: 8px;
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 20px;
    margin-bottom: 10px;
}

#product-main .left-prod-detail {
    height: 380px;
    border: 2px solid #f8f9fa;
}

#product-main .cover-img>img {
    width: 100%;
    /* height: auto; */
    height: 100%;
    object-fit: contain;
}

#product-main .cover-img>div {
    height: 100% !important;
}

#product-main .cover-img>div>div {
    background-color: #fff;
}

/* Skeleton Loader Page  */
#product-main .cover-img-load {
    padding: 10px;
}

#product-main .cover-img-load .ant-skeleton {
    height: 100%;
    width: 100%;
}

#product-main .cover-img-load .ant-skeleton .ant-skeleton-image {
    height: 100%;
    width: 100%;
}

#product-main .cover-img-load .ant-skeleton .ant-skeleton-image>span {
    display: none;
}

/* Skeleton Loader Page  */

#product-main .cover-img>div>div>img {
    object-fit: contain;
}

#product-main .cover-img>div>img {
    /* width: 100%; */
    /* height: auto; */
    height: 100% !important;
    object-fit: contain !important;
}

#product-main .cover-img {
    height: 100%;
    padding: 5px;
}

#product-main .multi-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding: 4px;
    background-color: #f8f9fa;
    height: 100%;
    overflow-y: auto;
}

#product-main .multi-img>img {
    width: 100%;
    cursor: pointer;
    object-fit: contain;
}

#product-main .selectedImage {
    border: 1px solid #3066fb;
}

/* Right  */
#product-main .right-prod-detail {
    padding-left: 20px;
    padding-top: 5px;
}

#product-main .order-text {
    font-weight: 500;
    color: #212121;
    font-size: 18px;
    margin-bottom: 10px;
}

#product-main .right-prod-detail .product-title {
    color: #212121;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
}

#product-main .right-prod-detail .product-barcode {
    color: #878787;
    font-weight: 500;
    font-size: 14px;
}

#product-main .right-prod-detail .stock {
    font-weight: 500;
    font-size: 14px;
}

#product-main .right-prod-detail .stock.red {
    color: red;
}

#product-main .product-main-bottom {
    margin: 16px 0px;
}

#product-main .product-main-bottom .ant-select-selection-placeholder {
    text-transform: capitalize;
}

#product-main .table-size-menu {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
}

#product-main .table-size-menu .dark {
    color: #212121;
    font-weight: 500;
}



.description-main {
    border-radius: 8px;
    /* background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    /* padding: 20px; */
    /* margin-top: 20px; */
    /* margin-bottom: 10px; */
}

.description-main .description-main-data {
    max-height: 400px;
    overflow-y: auto;
}

.description-main .title {
    color: #212121;
    font-size: 17px;
    font-weight: 500;
}

.description-main>div img {
    max-width: 100%;
    object-fit: contain;
}

.single-prod-height {
    height: 63px;
}


@media screen and (max-width: 768px) {
    #product-main .left-prod-detail {
        height: 330px;
    }

    /* Right  */
    #product-main .right-prod-detail {
        padding-top: 20px;
        padding-left: 5px;
    }
}

@media screen and (max-width: 575px) {
    #product-main .left-prod-detail {
        height: 250px;
    }
}
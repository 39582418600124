.App {
    height: 100vh;
    overflow: auto;
}

#main-section {
    width: 100%;
    position: relative;
    padding: 5px;
}


.main-div {
    width: 100%;
}

.content-div {
    width: 100%;
    height: calc(100% - 10px);
    display: flex;
    gap: 5px;
}


#right-section {
    flex-grow: 1;
}


#project-header.default-fixed-sidebar {
    width: 220px;
}

.content-div.row {
    --bs-gutter-x: 0px;
    margin-left: 0px;

    .dummy-header {
        display: none;
    }

    #project-header .main-group {
        position: static;
        height: 100%;
    }

}

#right-section.default-fixed-content {
    width: calc(100% - 255px);
}


@media (max-width: 991px) {
    .content-div {
        position: relative;

        #project-header {
            position: absolute;
            z-index: 12;
            width: 100%;
            height: 100%;
            max-width: 220px;

            .main-group {
                position: static;
                min-height: 300px;
            }
        }

        .display-none {
            display: none !important;
        }

        .dummy-header {
            display: none;
        }

        #right-section {
            width: 100%;
        }
    }
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');

* {
  /* border: 1px solid black; */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  /* line-height: normal; */
  /* color: #4F4F4F; */
}

:root {
  /* --theme-color: #3066fb;
  --heading-color: #3c4043;
  --neutral: #d9d9d9;
  --placeholder: #919191;
  --selected-shade: #f2f7fd;
  --table-background: #f5f5f5;
  --detailed-font: #333333;
  --green: rgb(12, 124, 12); */
  --theme-color: #3066fb;
  --heading-color: #3C4043;
  --neutral: #D9D9D9;
  --placeholder: #919191;
  --selected-shade: #F2F7FD;
  --table-background: #F5F5F5;
  --detailed-font: #333333;
  --green: rgb(12, 124, 12);
  --ant-theme-color: #1677FF;
  --white-color: #fff;
  /* --white-color: rgb(50, 50, 50); */
  --black-color: #000;
  --dark-grey: #444444;
  --black-shade-2: #000000D9;
  --light-grey: #848484;
  --grey-shade: #555;
  --grey-shade-1: #888;
  --grey-shade-2: #666A70;
  --white-shade-1: #F9F9F9;
  --white-shade-2: #DADCDF;
  --sky-blue: #9DB7FC;
  --light-red: #EE4E3F;
  --red-shade-2: #FF6A6A;
  --light-black: #282C34;
  --grey-shade-3: #4B5058;
  --grey-shade-4: #73777C;
  --grey-shade-5: #969696;
  --grey-shade-6: #777777;
  --grey-shade-7: #999999;
  --grey-shade-8: #666666;
  --grey-shade-9: #817E8D;
  --grey-shade-10: #666;
  --grey-shade-11: #AAAAAA;

  /* this is for header - nav bg - border  */
  --main-bg: #f8f9fa;
  --border-dadcdf: #DADCDF;
  /* --chart-gridLine: #73777C; */
  --chart-gridLine: #e6e6e6;

  /* this is for Table Color and Background  */
  --table-head-bg: #fafafa;
  --table-rows-bg: #fff;

  /* all-input(border,text,placeholder,date) */
  --border-All-color: #D4D4D4;
  ;
  --background-All-color: #ffffff;

  /* select,input placeholder */
  --input-placeholder-color: rgba(0, 0, 0, 0.25);
  --selected-placeholder-color: black;
  --datepicker-color: rgba(0, 0, 0, 0.88);

  /* commonlabel-input */
  --label-input-color: #3c4043;

  /* tabel-header-text */
  --tabel-head-text-color: #3c4043;
  ;

  /* tabel-row background */
  --tabel-row-back-color: lightblue;

  /* disable-button */
  --disable-button-common-color: rgba(0, 0, 0, 0.04);
  --button-common-color: #1677ff;

}

ul {
  list-style: none;
}

a {
  text-decoration: none !important;
}

/* User Top-Header Right Text  */
.details-menu-top-menu-popup .ant-popover-inner {
  padding: 8px;
}

.details-menu-top-menu-popup .ant-popover-inner .ant-popover-inner-content {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.selectedPreset {
  background-color: #F2F7FD;
  color: #3066FB;
}

.details-menu-top-menu-popup .ant-popover-inner .ant-popover-inner-content>div>a,.dynamic-link {
  padding: 7px 12px;
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  color: #333;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.dynamic-link{
cursor: pointer;
}
.details-menu-top-menu-popup .ant-popover-inner .ant-popover-inner-content>div:hover>a,.details-menu-top-menu-popup .ant-popover-inner .ant-popover-inner-content>div:hover>.dynamic-link {
  background-color: #f0f0f0;
}

/*black overlay*/
.bg-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 3;
  visibility: hidden;
  /* transition: 0.5s; */
  top: 0;
  left: 0;
}

.bg-overlay.open {
  z-index: 3;
  opacity: 1;
  visibility: visible;
}

:where(.css-dev-only-do-not-override-190m0jy).ant-btn>span {
  color: var(--white-color) !important;
}

.pages {
  background: var(--main-bg);
  padding: 8px;
  overflow: hidden;
  height: 100vh;
}

/* Dhruv User Page  */
#main-section-user.pages {
  padding: 0;
  height: 100vh;
  overflow-y: scroll;
  background: initial;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
  background: #fafafa;
}

::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 18px;
}

@media (max-width: 767px) {
  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
}

/*breadcumb*/
.pages .breadcumb {
  padding-top: 12px;
  padding-bottom: 9px;
}

#dashboard-main.style-menu {
  position: relative;
}

.back-menu {
  width: 13%;
}

.more-outlined {
  display: none;
}

.tr-hover:hover .more-outlined {
  display: inline-block;
}

.ant-btn-sm span {
  color: black
}

.MoreOutlined-Button {
  /* background-color: #E0E0E0; */
  border-style: none;
}

.tr-hover:hover .more-outlined .MoreOutlined-Button {
  display: inline-block;
  /* background-color: #FAFAFA; */
}

.ant-dropdown-open .MoreOutlined-Button {
  visibility: visible;
  border-style: none;
  background-color: #E0E0E0 !important;
}

.MoreOutlined-Button:hover {
  background-color: #E0E0E0 !important;
  border-style: none;
}

/* Edit- Delete - Button - Action  DHRUV  */
.edit-delete-action .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 0;
}

.edit-delete-action .ant-dropdown-menu .ant-dropdown-menu-item .edit-delete-action-li {
  display: flex;
  align-items: center;
  gap: 8px;
  /* padding: 5px 12px; */
  padding: 4px 12px;

  color: #73777c;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 15px;
}

.MoreOutlined {
  visibility: hidden;
}

.ant-table-row:hover .MoreOutlined {
  visibility: visible;
}

.ant-dropdown-open {
  visibility: visible;
}


@media (min-width: 992px) {
  .col-lg-11 {
    flex: 0 0 auto;
    /* width: 94.99%; */
  }
}

.menu-change-style {
  position: absolute;
  left: 5.2%;
  z-index: 100;
  height: 98vh;
  width: 94.4% !important;
  background: rgba(0, 0, 0, 0.1);
}

/* .menu-change-style2 { */
/* position: absolute;
  left: 96px;
  z-index: 100;
  height: 100%; */
/* width: 100% ; */
/* background: rgba(0, 0, 0, 0.1); */
/* } */

/* .pages .breadcumb .left p {
  font-size: 14px;
  font-weight: 500;
}
.pages .breadcumb .left p a {
  color: #00000073;
  transition: 0.3s;
}
.pages .breadcumb .left p a:hover {
  color: var(--theme-color);
}
.pages .breadcumb .left p span {
  padding: 0 8px;
} */

.pages .breadcumb .left nav ol li a {
  font-size: 14px;
  font-weight: 500;
}

/* DHRUV - BREADCUMP CHANGE  */
.pages .breadcumb .left nav ol li a span {
  padding: 0px 6px 0px 2px;
}

/* .pages .breadcumb .left nav ol li a span {
  padding: 0px 8px;
} */

.pages .breadcumb .left nav ol li a {
  color: #00000073;
  transition: 0.3s;
}

.pages .breadcumb .left nav ol li a:hover {
  color: var(--theme-color);
}

@media (max-width: 767px) {
  .pages .breadcumb .right {
    margin-top: 10px;
  }

  .pages .breadcumb {
    padding: 8px 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .pages .breadcumb {
    padding: 8px 0;
  }

  .pages .breadcumb .left p {
    font-size: 12px;
  }
}

@media (min-width: 992px) and (max-width: 1500px) {
  .pages .breadcumb .left p {
    font-size: 12px;
  }

  .pages .breadcumb {
    padding-top: 10px;
    padding-bottom: 7px;
  }

  .pages .breadcumb .left p {
    font-size: 12px;
  }
}

.refresh {
  color: var(--theme-color);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.refresh .anticon {
  margin-right: 8px;
}

/* .refresh:hover span{
  color: grey;
} */

.refresh svg {
  font-family: "Roboto";
  font-size: 18px;
}

.refresh div {
  font-family: "Roboto";
  font-size: 14px;
}

.refresh .isFetching svg,
.refresh .isFetching {
  color: #969696;
}

.refresh .notFetching svg,
.refresh .notFetching {
  color: var(--theme-color);
}

.refresh:hover svg,
.refresh:hover div {
  color: #969696;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

/* ant confirm button color */
.ant-popconfirm-buttons .ant-btn:last-child span {
  color: var(--white-color);
}

/* common ant table sckeleton set */

.my-table-sckeleton .ant-skeleton .ant-skeleton-title {
  margin-block-start: 0px !important;
}

.my-table-sckeleton .ant-skeleton .ant-skeleton-paragraph {
  margin-block-start: 10px !important;
}

/* common pagination css */
.my-pagination .ant-pagination .ant-pagination-item a {
  color: #3c4043;
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  transition: color 0s ease-in;
}

.my-pagination .ant-pagination li {
  transition: 'background' 0s ease-in;
  border-radius: 50%;
}

.my-pagination .ant-pagination li.ant-pagination-item-active {
  border-color: var(--theme-color);
}

.my-pagination .ant-pagination li.ant-pagination-item-active {
  /* border-radius: 100px; */
  background: var(--theme-color);
}

.my-pagination .ant-pagination li.ant-pagination-item-active:hover a {
  color: var(--white-color);
  /* text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500; */
}

.my-pagination .ant-pagination li.ant-pagination-item-active a {
  font-size: 15px;
  color: var(--white-color);
  /* text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500; */
}

.my-pagination .ant-table-pagination-right {
  margin: 16px 0 0 0 !important;
}

.my-pagination .ant-pagination .ant-pagination-total-text,
.my-pagination .ant-pagination .ant-pagination-options-quick-jumper,
.my-pagination .ant-pagination .ant-select-selection-item {
  font-size: 15px;
  color: #3c4043;
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
}

/* detailed menu font*/
.detailed-menu .normal-menu ul .detailed-menu-main-li span svg {
  width: 18px;
  height: 18px;
}

.detailed-menu .normal-menu ul .detailed-menu-main-li .detailed-heading-font {
  font-family: "Roboto";
  color: var(--detailed-font);
  font-weight: 500;
  font-size: 14px;
}

.detailed-menu .normal-menu ul .detailed-menu-main-li .submenu ul li a {
  color: #888888;
  font-size: 14px;
  font-weight: 400;
}

.detailed-menu .normal-menu ul .detailed-menu-main-li .detailed-menu-main-a.active .detailed-heading-font {
  color: var(--theme-color);
}

.detailed-menu .normal-menu ul .detailed-menu-main-li:hover .detailed-heading-font {
  color: var(--theme-color);
}

.detailed-menu .normal-menu ul .detailed-menu-main-li a.open-arrow .detailed-heading-font {
  color: var(--theme-color);
}

.ant-typography-copy {
  /* max-height: 17px; */
  margin-bottom: 0px;
}

.div:where(.css-dev-only-do-not-override-p7e5j5).ant-typography,
:where(.css-dev-only-do-not-override-p7e5j5).ant-typography p {
  margin-bottom: 0px;
}

.mySearch:hover .ant-input-group-addon .ant-btn,
.mySearch:hover .ant-input-affix-wrapper {
  border: 1px solid var(--theme-color);
}

/* common table file font size color  */
.tableSetCss thead tr th div,
.tableSetCss thead tr th span,
.tableSetCss thead tr th h6,
.tableSetCss thead tr th h4,
.tableSetCss thead tr th h3,
.tableSetCss thead tr th h2,
.tableSetCss thead tr th p,
.tableSetCss thead tr th h1,
.adreport-thead {
  color: var(--tabel-head-text-color) !important;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
}

.tableSetCss tbody tr td div,
.tableSetCss tbody tr td span,
.tableSetCss tbody tr td h6,
.tableSetCss tbody tr td h4,
.tableSetCss tbody tr td h3,
.tableSetCss tbody tr td h2,
.tableSetCss tbody tr td p,
.tableSetCss tbody tr td h1 {
  /* color: red; */
  color: #7c7473;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 15px;
}

.tableSetCss tbody tr td div.difColor,
.tableSetCss tbody tr td span.difColor,
.tableSetCss tbody tr td h6.difColor,
.tableSetCss tbody tr td h4.difColor,
.tableSetCss tbody tr td h3.difColor,
.tableSetCss tbody tr td h2.difColor,
.tableSetCss tbody tr td p.difColor,
.tableSetCss tbody tr td h1.difColor {
  color: #3c4043;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 15px;
}

.tableSetCss tbody tr td div.themeColor,
.tableSetCss tbody tr td span.themeColor,
.tableSetCss tbody tr td h6.themeColor,
.tableSetCss tbody tr td h4.themeColor,
.tableSetCss tbody tr td h3.themeColor,
.tableSetCss tbody tr td h2.themeColor,
.tableSetCss tbody tr td p.themeColor,
.tableSetCss tbody tr td h1.themeColor {
  color: var(--theme-color);
  font-family: "Roboto";
  font-weight: 400;
  font-size: 15px;
}

.tableSetCss tbody tr td div.mySvg svg,
.tableSetCss tbody tr td span.mySvg svg,
.tableSetCss tbody tr td h6.mySvg svg,
.tableSetCss tbody tr td h4.mySvg svg,
.tableSetCss tbody tr td h3.mySvg svg,
.tableSetCss tbody tr td h2.mySvg svg,
.tableSetCss tbody tr td p.mySvg svg,
.tableSetCss tbody tr td h1.mySvg svg {
  font-size: 20px;
}

.tableSetCss tbody tr .anticon-eye-invisible svg {
  font-size: 20px;
  color: #555555;
}

.tableSetCss tbody tr .apikey_color {
  color: #73777c;
  width: 90%;
}

.tableSetCss .project-id-records {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.tableSetCss tbody tr .copy-play-store {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

/* copy icon common */
.tableSetCss tbody tr .ant-typography-copy svg {
  height: 16px;
  width: 16px;
  color: #999999;
}

/* .tableSetCss :where(.css-dev-only-do-not-override-3mqfnx).ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
:where(.css-dev-only-do-not-override-3mqfnx).ant-table-wrapper .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background: #dadcdf;
} */

.tableSetCss .ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
.tableSetCss .ant-table-wrapper .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background: #dadcdf;
}

.tableSetCss .ant-table-thead {
  border-bottom: 1px solid red !important;
}

.tableSetCss .platformIcon svg {
  color: #555555;
}

/* tabel header background */
.ant-table-wrapper .ant-table-thead>tr>th {
  background-color: #eee !important;
  /* background-color: #fafafa !important; */
}

/* .ant-table-cell {
  background-color: rgba(0, 255, 255, 0.171);
} */

/* tabel header background for expand icon */
.ant-table-thead td.ant-table-row-expand-icon-cell {
  background-color: #eee !important;
}

/* table-row-hover */
.ant-table-cell-row-hover {
  background-color: #FAFAFA !important;
}

/* scroll-bar for all */
.dynamic-scroll-all::-webkit-scrollbar,
.ant-table-body::-webkit-scrollbar {
  width: 5px;
}

.dynamic-scroll-all::-webkit-scrollbar-track,
.ant-table-body::-webkit-scrollbar-track {
  background: #FAFAFA;
}

.dynamic-scroll-all::-webkit-scrollbar-thumb,
.ant-table-body::-webkit-scrollbar-thumb {
  background-color: #D9D9D9;
  border-radius: 20px;
  border: 3px
}

/* common label set */
.commonLabel {
  font-size: 15px !important;
  color: var(--label-input-color);
  font-weight: 400;
  font-family: 'Roboto';
  font-style: normal;
  line-height: 22px !important;
}

/* button-color */
.button-color .ant-btn-primary {
  background: var(--button-common-color);
}

.button-color .ant-btn-primary:disabled {
  background: var(--disable-button-common-color);
  color: rgba(0, 0, 0, 0.25);
}

.required {
  color: red;
  font-size: 16px;
  line-height: 1;
  margin-left: 1px;
}

/* select,input placeholder */
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder,
.ant-select-selection-placeholder,
input[type="text"]::placeholder {
  color: var(--input-placeholder-color) !important;
}


/* selected placeholder */
.ant-select-selection-item {
  color: var(--selected-placeholder-color) !important;
  text-transform: capitalize;
}

/* date-picker all text */
.ant-picker .ant-picker-input>input,
.ant-picker-separator,
.ant-picker-suffix {
  color: var(--datepicker-color) !important;
}

/* all-input field background */
.ant-picker-range,
.ant-input-affix-wrapper,
input[type=text],
.ant-select-selector,
.mySearch .ant-input-group-addon .ant-btn {
  background-color: var(--background-All-color) !important;
}

/* input-type file color */
input[type=file]::file-selector-button {
  color: #212529 !important;
  background: #E9ECEF !important;
}

.form-group .form-control,
.form-groups .form-control,
.ant-picker-range,
.ant-select-selector,
.ant-input-affix-wrapper,
.mySearch .ant-input-group-addon .ant-btn,
.user-search .ant-input,
.border-input .ant-input,
.ant-color-picker-trigger,
.ant-pagination-options-quick-jumper input {
  border: 1px solid var(--border-All-color) !important;
}

.height-set-Description {
  padding-top: 8px;
}

.left-nav a svg {
  /* fill: red; */
  fill: #4f4f4f;
}

.commonPageTitle {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  padding-bottom: 4px;
  border-bottom: 1px dashed var(--light-grey);
  display: inline;
  color: var(--theme-color);
  font-family: "Roboto";
}

.commonTableTitle {
  padding-bottom: 4px;
  border-bottom: 1px dashed var(--light-grey);
  display: inline;
  color: var(--theme-color);
  font-family: "Roboto";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.commonAppicon img {
  width: 33px;
  height: 33px;
}

@media (max-width: 767px) {
  .refresh svg {
    font-size: 14px;
  }

  .refresh div {
    font-size: 12px;
  }

  .detailed-menu .normal-menu ul .detailed-menu-main-li span svg {
    width: 16px;
    height: 16px;
  }

  .detailed-menu .normal-menu ul .detailed-menu-main-li .detailed-heading-font {
    font-size: 12px;
  }

  .detailed-menu .normal-menu ul .detailed-menu-main-li .submenu ul li a {
    text-wrap: nowrap;
    font-size: 12px;
  }

  .tableSetCss thead tr th div,
  .tableSetCss thead tr th span,
  .tableSetCss thead tr th h6,
  .tableSetCss thead tr th h4,
  .tableSetCss thead tr th h3,
  .tableSetCss thead tr th h2,
  .tableSetCss thead tr th p,
  .tableSetCss thead tr th h1 {
    font-size: 12px;
  }

  .tableSetCss tbody tr td div,
  .tableSetCss tbody tr td span,
  .tableSetCss tbody tr td h6,
  .tableSetCss tbody tr td h4,
  .tableSetCss tbody tr td h3,
  .tableSetCss tbody tr td h2,
  .tableSetCss tbody tr td p,
  .tableSetCss tbody tr td h1 {
    font-size: 12px;
  }

  .my-pagination .ant-pagination .ant-pagination-total-text,
  .my-pagination .ant-pagination .ant-pagination-options-quick-jumper,
  .my-pagination .ant-pagination .ant-select-selection-item {
    font-size: 12px;
  }

  .my-pagination .ant-pagination li a {
    font-size: 12px;
  }

  .my-pagination .ant-pagination li.ant-pagination-item-active a {
    font-size: 12px;
  }

  .tableSetCss tbody tr td div.mySvg svg,
  .tableSetCss tbody tr td span.mySvg svg,
  .tableSetCss tbody tr td h6.mySvg svg,
  .tableSetCss tbody tr td h4.mySvg svg,
  .tableSetCss tbody tr td h3.mySvg svg,
  .tableSetCss tbody tr td h2.mySvg svg,
  .tableSetCss tbody tr td p.mySvg svg,
  .tableSetCss tbody tr td h1.mySvg svg {
    font-size: 16px;
  }

  .commonLabel {
    font-size: 12px !important;
    line-height: 16px !important;
  }

  .commonPageTitle {
    font-size: 14px;
  }

  .commonTableTitle {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .refresh svg {
    font-size: 15px;
  }

  .refresh div {
    font-size: 13px;
  }

  .detailed-menu .normal-menu ul .detailed-menu-main-li span svg {
    width: 18px;
    height: 18px;
  }

  .detailed-menu .normal-menu ul .detailed-menu-main-li .detailed-heading-font {
    font-size: 12px;
  }

  .detailed-menu .normal-menu ul .detailed-menu-main-li .submenu ul li a {
    text-wrap: nowrap;
    font-size: 12px;
  }

  .tableSetCss thead tr th div,
  .tableSetCss thead tr th span,
  .tableSetCss thead tr th h6,
  .tableSetCss thead tr th h4,
  .tableSetCss thead tr th h3,
  .tableSetCss thead tr th h2,
  .tableSetCss thead tr th p,
  .tableSetCss thead tr th h1 {
    font-size: 12px;
  }

  .tableSetCss tbody tr td div,
  .tableSetCss tbody tr td span,
  .tableSetCss tbody tr td h6,
  .tableSetCss tbody tr td h4,
  .tableSetCss tbody tr td h3,
  .tableSetCss tbody tr td h2,
  .tableSetCss tbody tr td p,
  .tableSetCss tbody tr td h1 {
    font-size: 12px;
  }

  .my-pagination .ant-pagination .ant-pagination-total-text,
  .my-pagination .ant-pagination .ant-pagination-options-quick-jumper,
  .my-pagination .ant-pagination .ant-select-selection-item {
    font-size: 12px;
  }

  .my-pagination .ant-pagination li a {
    font-size: 12px;
  }

  .my-pagination .ant-pagination li.ant-pagination-item-active a {
    font-size: 12px;
  }

  .tableSetCss tbody tr td div.mySvg svg,
  .tableSetCss tbody tr td span.mySvg svg,
  .tableSetCss tbody tr td h6.mySvg svg,
  .tableSetCss tbody tr td h4.mySvg svg,
  .tableSetCss tbody tr td h3.mySvg svg,
  .tableSetCss tbody tr td h2.mySvg svg,
  .tableSetCss tbody tr td p.mySvg svg,
  .tableSetCss tbody tr td h1.mySvg svg {
    font-size: 18px;
  }

  .commonLabel {
    font-size: 12px !important;
    line-height: 18px !important;
  }

  .commonPageTitle {
    font-size: 16px;
  }

  .commonTableTitle {
    font-size: 16px;
  }

}

@media (min-width: 992px) and (max-width: 1500px) {
  .refresh div {
    font-size: 13px;
  }

  .refresh svg {
    font-size: 16px;
  }

  .detailed-menu .normal-menu ul .detailed-menu-main-li span svg {
    width: 14px;
    height: 14px;
  }

  .detailed-menu .normal-menu ul .detailed-menu-main-li .detailed-heading-font {
    font-size: 12px;
  }

  .detailed-menu .normal-menu ul .detailed-menu-main-li .submenu ul li a {
    text-wrap: nowrap;
    font-size: 12px;
  }

  .tableSetCss thead tr th div,
  .tableSetCss thead tr th span,
  .tableSetCss thead tr th h6,
  .tableSetCss thead tr th h4,
  .tableSetCss thead tr th h3,
  .tableSetCss thead tr th h2,
  .tableSetCss thead tr th p,
  .tableSetCss thead tr th h1 {
    font-size: 14px;
  }

  .tableSetCss tbody tr td div,
  .tableSetCss tbody tr td span,
  .tableSetCss tbody tr td h6,
  .tableSetCss tbody tr td h4,
  .tableSetCss tbody tr td h3,
  .tableSetCss tbody tr td h2,
  .tableSetCss tbody tr td p,
  .tableSetCss tbody tr td h1 {
    font-size: 14px;
  }

  .my-pagination .ant-pagination .ant-pagination-total-text,
  .my-pagination .ant-pagination .ant-pagination-options-quick-jumper,
  .my-pagination .ant-pagination .ant-select-selection-item {
    font-size: 14px;
  }

  .my-pagination .ant-pagination li a {
    font-size: 14px;
  }

  .my-pagination .ant-pagination li.ant-pagination-item-active a {
    font-size: 14px;
  }

  .tableSetCss tbody tr td div.mySvg svg,
  .tableSetCss tbody tr td span.mySvg svg,
  .tableSetCss tbody tr td h6.mySvg svg,
  .tableSetCss tbody tr td h4.mySvg svg,
  .tableSetCss tbody tr td h3.mySvg svg,
  .tableSetCss tbody tr td h2.mySvg svg,
  .tableSetCss tbody tr td p.mySvg svg,
  .tableSetCss tbody tr td h1.mySvg svg {
    font-size: 20px;
  }

  .commonLabel {
    font-size: 13px !important;
    line-height: 18px !important;
  }

  .commonPageTitle {
    font-size: 14px;
  }

  .commonTableTitle {
    font-size: 14px;
  }
}

.green {
  color: var(--green) !important;
}

.ant-select-selector:hover {
  border-color: var(--theme-color) !important;
}

.copy-play-store .ant-typography {
  margin-bottom: 0px;
}

.ant-typography {
  margin-bottom: 0px !important;
}

/* div:where(.css-dev-only-do-not-override-p7e5j5).ant-typography,
:where(.css-dev-only-do-not-override-p7e5j5).ant-typography p {
  margin-bottom: 0px;
} */

.ant-input-remove-marg .ant-form-item {
  margin-bottom: 0px !important;
}

.delete-Class-action-menu {
  padding: 0px !important;
}

.delete-Class-action-menu .ant-dropdown-menu-title-content span {
  padding: 6px 12px;
}

.ant-pagination-options {
  display: block !important;
}

/* .ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-thead>tr>td {
  background-color: var(--table-head-bg);
} */

@media only screen and (max-width: 576px) {
  .ant-pagination .ant-pagination-options {
    display: block;
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  .ant-typography-copy svg {
    width: 14px;
    height: 15px;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .ant-typography-copy svg {
    width: 14px;
    height: 15px;
  }
}
.refresh-button-users {
	position: absolute;
	right: 20px;
	top: 20px;
}

/*Project Records*/
#dashboard-page.project-records {
	/* margin-top: 10px; */
	background: var(--white-color);
	border: 1px solid var(--border-dadcdf);
	border-radius: 8px;
	padding: 20px;
}

#dashboard-page.project-records .header .right .form-group {
	margin-right: 16px;
	width: 100%;
	position: relative;
}

#dashboard-page.project-records .header .right .form-group i {
	position: absolute;
	/* top: 60%; */
	top: 87%;
	transform: translateY(-85%);
	right: 14px;
}

#dashboard-page.project-records .header .right .form-group .form-control {
	margin-top: 8px;
	border-radius: 6px;
	border: 1px solid #D4D4D4;
	padding: 7px 14px;
	color: #333;
	font-size: 14px;
	font-weight: 500;
	transition: 0.3s;
	box-shadow: none;
}

#dashboard-page.project-records .header .right .form-group .form-control::placeholder {
	color: #888;
	font-size: 14px;
	font-weight: 400;
}

#dashboard-page.project-records .header .right .form-group .form-control:focus {
	border-color: var(--theme-color);
}

#dashboard-page.project-records .header .right .form-group.platform,
#dashboard-page.project-records .header .right .form-group.status {
	/* max-width: 125px; */
	max-width: 162px;
}

#dashboard-page.project-records .header .right .form-group.searchSubCategory {
	max-width: 290px;
}

#dashboard-page.project-records .header .right .form-group.search {
	max-width: 350px;
}

#dashboard-page.project-records .header .right .form-group .search-icon {
	right: 10px;
}

#dashboard-page.project-records .body {
	margin-top: 28px;
}



@media (max-width: 767px) {
	.custom-height {
		height: 30px;
	}

	#dashboard-page .col-lg-9.header-custom-col {
		width: 100%;
		overflow-y: auto;
	}

	#dashboard-page .project {
		padding: 12px;
	}
/* 
	#dashboard-page .project h2 {
		font-size: 14px;
	} */

	#dashboard-page .project .body .form-group {
		max-width: 100% !important;
		margin-top: 12px;
	}

	/* #dashboard-page .project .body .form-group label {
		font-size: 12px;
	} */

	#dashboard-page .project .body .form-group .form-control {
		font-size: 12px;
	}

	#dashboard-page .project .body {
		margin-top: 5px;
	}

	#dashboard-page.project-records {
		padding: 12px;
	}

	/* #dashboard-page.project-records .header .left h2 {
		font-size: 14px;
	} */

	#dashboard-page.project-records .header .right .form-group {
		max-width: 100% !important;
		margin-top: 12px;
	}

	/* #dashboard-page.project-records .header .right label {
		font-size: 12px;
	} */
}

@media (min-width: 768px) and (max-width: 991px) {
	#dashboard-page .col-lg-9.header-custom-col {
		width: 100%;
	}

	#dashboard-page .project {
		padding: 16px;
	}

	#dashboard-page.project-records {
		padding: 16px;
	}

	#dashboard-page .project .body {
		margin-top: 20px;
	}

	#dashboard-page.project-records .body {
		margin-top: 20px;
	}

	#dashboard-page.project-records .header .right .form-group.platform,
	#dashboard-page.project-records .header .right .form-group.status {
		/* max-width: 80px; */
		max-width: 100px;
	}
}

@media (min-width: 992px) and (max-width: 1500px) {
	#dashboard-page .project {
		padding: 10px;
	}

	#dashboard-page .project .body {
		margin-top: 20px;
	}

	#dashboard-page .project .body .form-group .form-control {
		padding: 4px 10px;
		font-size: 12px;
		height: 34px;
	}

	#dashboard-page.project-records {
		padding: 20px 10px;
	}

	#dashboard-page.project-records .header .right .form-group .form-control {
		padding: 5px 7px;
		font-size: 12px;
	}

	#dashboard-page.project-records .header .right .form-group.platform,
	#dashboard-page.project-records .header .right .form-group.status {
		/* max-width: 80px; */
		max-width: 150px;
	}

	#dashboard-page.project-records .header .right .form-group.searchSubCategory {
		max-width: 280px;
	}

	#dashboard-page.project-records .header .right .form-group {
		margin-right: 15px;
	}

	#dashboard-page.project-records .body {
		margin-top: 14px;
	}
}

.custom-width {
	/* margin-right: 20px; */
	margin-right: 13px;
	/* width: 100%; */
}
.userIcon{
cursor: pointer;
}
.height-set {
    padding-top: 10px;
}

#login-page .ant-input-remove-marg {
    margin-bottom: 0px !important;
}

.height-otpbtn {
    height: 71px;
}

#login-page .ant-form-item {
    margin-bottom: 0px !important;
}
#track-orders {
    background: var(--white-color);
    border: 1px solid var(--border-dadcdf);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 10px;
}

#track-orders .ant-steps-item-title {
    display: block;
}

#track-orders .ant-steps-item-title>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}


#track-orders .trackin-order-title {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.2px;
    color: #3C4043;
    margin-bottom: 16px;
}

#track-orders .ant-steps-item .ant-steps-item-description {
    padding-bottom: 18px;
}
